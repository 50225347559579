html{
  overflow-x: hidden;
}


:root{
  --header-bg-color:#f9f9f9;
  --nav-bg:white;
  --nav-font-weight:500;
  --nav-hover-font-weight:600;
  --nav-current-color:green;
  --primary-section-bg:#f6f6f6;
  --alternating-section-bg:white;
  --letter-spacing:.2em;
  --btn-primary:hsl(156, 90%, 35%);
  --btn-primary-hover:hsl(156, 90%, 40%);
  --btn-secondary:hsl(265, 90%, 35%);
  --btn-secondary-hover:hsl(265, 90%, 40%);
  --light-text:white;

  --hero-title-ls:0;
  --hero-title-tt:unset;

  --subtitle-m:0 0 0 10px;
  --subtitle-ff:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /* --subtitle-text-transform:uppercase; */
  --subtitle-letter-spacing:var(--letter-spacing);
  --subtitle-fs:1rem;

  --big-img-mt:20px;

  --input-bg:rgba(0, 0, 0, 0.15);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  box-sizing: border-box !important;
  letter-spacing: var(--letter-spacing);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.light-text{
  color:rgb(247, 247, 247);
}

button{
  font-size: 1.3rem;
  padding:.3em .6em;
  border:none;
  border-radius: 3px;
  cursor: pointer;

}


.lower-case{
  text-transform: capitalize !important;
}

.normal-spacing{
  letter-spacing: 0;
  line-height: 1.6em;
}

.text-left{
  text-align: left;
}

.big{
  width: 650px;
  max-width: calc(100vw - 40px);
  margin-top: 1rem;
}

.btn-primary{
  padding:1.3em 2.2em;
  /* text-transform: uppercase; */
  font-size: 1rem;
  background-color: var(--btn-primary);
  color:var(--light-text);
  font-weight: 550;
  letter-spacing: 0.2em;
}

.btn-primary:hover{
  background-color: var(--btn-primary-hover);
}

.faded{
  opacity: .3;
}

.toggle-cont{
  display: grid;
  grid-template-columns: 50px 50px;
  align-items: center;
  justify-items: center;
}

.edit-section input{
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:0;
}



.btn-secondary{
  background: var(--btn-secondary);
}
.btn-secondary:hover{
  background: var(--btn-secondary-hover);
}

.toggle-header{
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-header .toggle-cont{
  display: flex;
  margin-left:10px;
  
}

.toggle-header label {
    margin-right:10px
}

.edit-section input{
  padding:0;
  margin-bottom:1em;
}

input[name="hero-title"]{
  text-transform: var(--hero-title-tt);
  font-family: var(--subtitle-ff);
  letter-spacing: var(--hero-title-fs);
  font-size:var(--subtitle-fs)
}

input[name="subtitle"]{
  text-transform: var(--subtitle-text-transform);
  font-family: var(--subtitle-ff);
  letter-spacing: var(--hero-title-letter-spacing);
  font-size:var(--subtitle-fs);
  /* margin:var(--subtitle-m) */
}

.edit-img-cont{
  margin-top:var(--big-img-mt) !important;
}

.edit-img-cont input{
  margin-top: 30px;
  padding: 10px;
  background: var(--input-bg);
}

div.edit-social-div input{
  background: #544949;
  text-align: center;
  padding: 0.7em;
  margin-bottom: 0;
}


select{
  padding: .5em 1em;
  border: none;
  border-radius: 3px;
  font-size: 1.3rem;
}








@media only screen and (max-width: 600px) {
  .toggle-header .toggle-cont{
    display: flex !important;
  }
  div.big p{
    font-size: 1rem;
  }
  
}