.link-section{
    margin-bottom: 50px;
}

.links-cont{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.external-link{
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.link-edit-row{
    display: flex;
    justify-content: center;
    max-width: 80vw;
    flex-wrap: wrap;
    background: rgba(128, 128, 128, 0.18);
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
}

.link-edit-row .input-cont{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.link-edit-row .input-cont label{
    text-align: left;
    margin:20px;
    margin-bottom: 0;
    font-size: 1.2rem;
}

.link-edit-row input{
    margin:20px;
    width:300px
}

@media only screen and (max-width: 600px) {
    .external-link{
        font-size: 1.2rem;
    }
    .link-edit-row input{
        margin:10px;
        width:250px
    }
    
  }