.floater-cont{
    margin-right:1rem;
    margin-left: 1rem;
    position: relative;
}
.floater{
    position: absolute;
    right:0;
    background-color: red;
    color:white;
    border-radius: 50%;
    width:20px;
    height: 20px;
    text-align: center;
    padding: auto;
    transform: translate(50%,-50%);
}

.floater span{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-35%,-55%);
}