.event-form-cont{
    position: relative;
    background: #e8e8e8;
    width: 550px;
}

.event-form-cont>.top-area>h1,.event-form-cont>.top-area>h4{
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    padding-bottom:.3rem;
    margin-bottom:0
}

.event-form-cont>.top-area>h4{
    padding-top:.5rem;
    margin:0;
    font-weight:400
}

.event-form-cont>.top-area>button{
    transform: translateY(30%);
    margin-top:-10px;
    background-color: rgb(41 157 41 / 61%);
    color: rgb(235, 235, 235);
}

.event-form-cont>.top-area>button:hover{
    background-color: rgb(41 157 41 / 90%);
}

.event-table td:nth-child(odd) input{
    width: 150px;
}

.event-table td:nth-child(even) input{
    width: 250px;
}

.event-table .full-span{
    width: 94% !important;
}