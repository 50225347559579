.message-cont{
    width: 600px;
    max-width: 90vw;
    margin: auto;
    margin-bottom: 50px;
    background:rgb(236, 232, 232);
    padding: 20px;
}

.message-cont header{
    background:rgb(236, 232, 232);
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
}

.message-cont header h3{
    margin: .5rem;
    margin-left: .7rem;
}
.message-cont header h4{
    margin: .5rem;
    margin-left: .7rem;
    color: grey;
}


.message-cont p{
    text-align: left;
    width: 100%;
}