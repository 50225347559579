.text-and-image{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 2rem;
    padding-bottom: 0;
    color: rgb(230, 229, 231);
    letter-spacing: 0;
    justify-content: center;
}

.text-and-image img{
    border-radius: 10px;
    box-shadow: 0 0 10px 20px rgb(0 0 0 / 34%);
    /* width: 50vw; */
    width: 600px;
    max-width: 90vw;
}

.text-cont{
    width: 40vw;
    min-width: 300px;
    padding: 0px 30px;
}
.text-cont p{
    text-align: justify;
    font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
    .text-and-image{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        padding-bottom: 0;
        color: rgb(230, 229, 231);
        letter-spacing: 0;
        padding-top: 0;
    }
    
    .text-and-image img{
        border-radius: 10px;
        box-shadow: none;
        width: 100vw;
        border-radius: 0;
        
    }
    
    .text-cont{
        width: unset;
        padding: 0 !important;
    }
    .text-cont p{
        font-size: 1rem;
        text-align: justify;
        padding: 0 20px;
    }

}