.delete-circle{
    background: #f55d5d;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border-radius: 50%;
    color: white;
}

.delete-circle:hover{
    background: #d92525;
}