.section-heading{
    font-weight: 400;
    position: relative;
    padding: 0rem 2em;
}

.section-heading:before{
    position: absolute;
    content: "";
    top:57%;
    left:50%;
    transform: translate(-160%,-50%);
    width:100%;
    height: .1px;
    background-color: rgb(159, 158, 158);
}

.section-heading:after{
    position: absolute;
    content: "";
    top:57%;
    left:50%;
    transform: translate(60%,-50%);
    width:100%;
    height: .1px;
    background-color: rgb(159, 158, 158);
}