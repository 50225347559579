.why-style section{
    background: rgb(243, 239, 239);
    color:rgb(87, 58, 94);
}


.why-style .text-and-image{
    padding-bottom: 2rem;
}

.why-style .text-and-image img{
    box-shadow: 3px 3px  rgba(149, 139, 139, 0.535);
    border-radius: 7px;
}

.why-style .text-and-image .text-cont{
    min-width: 500px;
}

@media screen and (max-width:600px) {
    .why-style .text-and-image .text-cont{
        min-width: 300px;
    }
}