section.section{
    max-width: 100vw;
    overflow-x: hidden;
    padding-top:0;
    min-height: 100px;
    background-color: var(--primary-section-bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

section.section.alternating:nth-child(even){
    background-color: var(--alternating-section-bg);
}