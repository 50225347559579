.quote-section{
    width:350px;
    background:rgb(238, 233, 233);
    margin:2rem;
    padding:1rem;
    position: relative;
    margin-top:100px;
    border-radius: 10px;
    min-width: 200px;
}

.quote-section>.img-cont{
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top:0;
    left:50%;
    transform: translate(-50%,-50%);
    box-shadow: 0  0 3px black;
}

.quote-section img{
    height: 100px;

}

.quote{
    margin-top:40px;
    /* display: flex; */

}

.quotation-mark{
    font-size: 3rem;
    padding:0px 10px;
    transform:translateY(10px)
}

.attribution{
    color:#ab9625;
    font-size: 1.4rem;
    font-weight: 600;
}