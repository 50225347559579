.edit-img-cont{
    position: relative;
    width: 80vw;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-img-cont img,.edit-img-cont .drop-area{
    width: 80vw;
    max-width: 500px;

}

.drop-area{
    height: 80vw;
    max-height: 500px;
    background: rgb(58, 48, 48);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.drop-area.highlight, .drop-area:has(.dotted-div.highlight){
    background: black;
}


.dotted-div{
    width: calc(70vw);
    max-width: 440px;
    height: 70vw;
    max-height: 450px;
    position: absolute;
    border:4px white dotted;
}

.drop-area .file-icon{
    color:rgb(221, 220, 181);
}

.drop-area h2{
    width: 60%;
    color:white
}

.edit-section .modal-content h1{
    font-size:1.5rem !important ;
}

.edit-section .modal .close-modal-button{
    right: -140px;
    background: none;
}


.modal-content input{
    width: 300px;
    font-size:1rem !important ;
}

.edit-img-cont .btn-primary{
    text-transform: unset;
    letter-spacing: 0em;
    margin-bottom: 1em;
    padding: 0.7em;
}

.open-file-modal-btn{
    background: #d3c9b8;
    margin-top: 1rem;
    border-radius: 10px;
}