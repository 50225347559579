input,textarea{
    width:85vw;
    max-width: 750px;
    font-size: 1.1rem;
    padding: .7em;
    margin-bottom: 1em;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 3px 3px #00000003;
    cursor: pointer;
}

textarea{
    height: 7em;
    resize: none;
}

input:hover,textarea:hover{
    box-shadow: 0px 0 3px 3px #00000011;
}

.form-error{
    width: 300px;
    margin: auto;
    margin-top: .4rem;
    color:red;
    font-weight: 600;
}