.event-table{
    width: 100%;
    /* border-bottom: 1px rgb(232, 232, 232) solid; */
    padding: 1em 0;
}

.event-table td{
    text-align: left;
}

.event-table td.right{
    text-align: right;
}

.event-table td.emphasize{
    font-weight: 700;
    font-size: 1.5rem;
}

@media screen and (max-width:600px) {

    .event-table{
        padding: .2rem !important;
    }
    .event-table td{
        font-weight: 600;
        font-size: 1.2rem !important;
    }
    .event-table td.emphasize{
        font-weight: 600;
        font-size: 1.4rem !important;
    }
}