/* COLORS
//
//
//
*/

.blue-theme{
    --main-ff:'Open Sans', sans-serif;
    --font-color:hsl(195, 3%, 25%);
    --secondary-font-color:red;
    --header-bg-color:rgb(225, 232, 235);
    --main-bg-color:hsl(198, 20%, 95%);
    --alternating-bg-color:hsl(210, 30%, 93%);
    --backdrop:url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/C%C3%A9vennes_France_night_sky_with_stars_02.jpg/640px-C%C3%A9vennes_France_night_sky_with_stars_02.jpg");
    color:var(--font-color) !important;
}
.blue-theme header{
    background-color: var(--header-bg-color) !important;
}

.dark-theme .slider{
    background-color: hsl(0, 75%, 60%)!important;
}

.blue-theme section{
    background: var(--main-bg-color) !important;

}
.blue-theme section.alternating:nth-child(odd):not(.hero-comp .section){
    background: var(--alternating-bg-color) !important;
}

.dark-theme h1{
    color:var(--font-color) !important;
}

/* inputs */
.dark-theme input,.dark-theme textarea{
    color:rgb(211, 228, 225) !important;
    background-color:#544949 ;
}

/* events */
.dark-theme .event-form-cont,.dark-theme .event-table{
    background: black !important;
}


/* links */
.dark-theme a{
    color:var(--font-color) !important;
}


/* buttons */

.dark-theme button{
    background:hsl(0, 75%, 60%) !important;
}

.dark-theme .delete-circle{
    background:hsl(0, 75%, 60%) !important;
}

.dark-theme .social-icon{
    color: hsl(0, 75%, 60%) !important;
}




/* FONTS
FONTS
FONTS
FONTS
FONTS
*/

.blue-theme{
    font-family: 'Open Sans', sans-serif !important;
    /* text-transform: lowercase; */
}

.dark-theme header a,.dark-theme header div{
    font-size: 1.5rem;
}


.blue-theme h1{
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: .2em;
    /* text-transform: lowercase; */
    font-weight: 400 !important;
}

.blue-theme .one-liner{
    /* text-transform: lowercase !important; */
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1.2rem;
}

.blue-theme h2{
    font-family: var(--main-ff) !important;
    font-size: 2rem;
}

.blue-theme p{
    font-family: var(--main-ff) !important;
    letter-spacing: .1em;
    font-size: 1.4rem;
    text-align: justify;
}

.blue-theme input,.blue-theme textarea{
    font-family: var(--main-ff) !important;
    letter-spacing: .2em;
}

.blue-theme input,.blue-theme button{
    font-family: var(--main-ff) !important;
}

.blue-theme .section-heading{
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0;
}



/* Section Flourishes */

.blue-theme .section-heading::before{
    min-width: 150px;
    width:100%;
    left: 50%;
    height: 5px;
    top:-15px;
    transform: translateX(-50%);
    background-color: rgb(214, 219, 229);
}

.blue-theme .section-heading::after{
    min-width: 150px;
    width:100%;
    left: 50%;
    height: 5px;
    top:59px;
    transform: translateX(-50%);
    background-color: rgb(214, 219, 229);
}