.hero-comp h1,.hero-comp .h1{
    font-size: 4rem;
    margin-bottom: .2em;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;;
    /* letter-spacing: var(--letter-spacing); */
}

@media only screen and (max-width: 600px) {
    input[name="title"]{
        font-size: 2rem !important;
    }
    .hero-comp h1{
        font-size: 2.5rem;
    }
}