.three-grid-element{
    justify-content: flex-start !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    max-width: 300px !important;
}


.three-grid-element img{
    width: 190px !important;
    height: 200px;
    /* border: 8px #733c8a solid; */
    border-radius: 15px;
    box-shadow: 5px 5px 5px #00000066;
}

.three-grid-element p{
    font-size: 1.2rem;
    text-align: left !important;
    letter-spacing: 0;
}