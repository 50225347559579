.three-grid-cont{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding:20px
}

.three-grid-element{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:50px;
    max-width: 200px;
}

.three-grid-element img{
    width: 100px;
}

.three-grid-element p{
    text-align: justify;
}

@media only screen and (max-width: 900px) {
    .three-grid-cont{
        display: grid;
        grid-template-columns: 1fr;
        padding:0
    }
    .three-grid-element{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin:10px;
        max-width: 200px;
    }
    .three-grid-element h2{
        margin-top: 0;
    }
    .three-grid-element h2:first-of-type{
        margin-top: 10px;
    }
    
    .three-grid-element img{
        width: 100px;
    }
    
    .three-grid-element p{
        text-align: justify;
    }

  }