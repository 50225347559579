.social-icon{
    padding: 1.5em 3em;
    color:#544949;
    cursor: pointer;
}

.socials a:hover svg{
    color:rgb(55, 23, 23)
}

@media screen and (max-width:600px) {
    .social-icon{
        padding: 1.5em;
}}