.back-drop{
    position: fixed;
    width: 100vw;
    height: 100vw;
    background-image: var(--backdrop);
    background-size: 100vw;
}

@media screen and (max-width:740px) {
    .back-drop{
    height: 100vh;
    }
}