/* COLORS
//
//
//
*/

.dark-theme{
    --font-color:hsl(0, 77%, 95%);
    --secondary-font-color:red;
    --header-bg-color:black;
    --main-bg-color:hsla(0, 0%, 6%, 0.748);
    --alternating-bg-color:hsla(0, 0%, 4%, 0.781);
    --backdrop:url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/C%C3%A9vennes_France_night_sky_with_stars_02.jpg/640px-C%C3%A9vennes_France_night_sky_with_stars_02.jpg");
    color:var(--font-color) !important;
}



.light-theme header{
    background-color: #dfdfdf !important;
}

.dark-theme .slider{
    background-color: hsl(0, 75%, 60%)!important;
}

.dark-theme section{
    background: var(--main-bg-color) !important;

}
.dark-theme section.alternating:nth-child(even){
    background: var(--alternating-bg-color) !important;
}

.dark-theme h1{
    color:var(--font-color) !important;
}

/* inputs */
.dark-theme input,.dark-theme textarea{
    color:rgb(211, 228, 225) !important;
    background-color:#544949 ;
}

/* events */
.event-form-cont, .event-table{
    /* background: black !important; */
}


/* links */
.dark-theme a{
    color:var(--font-color) !important;
}


/* buttons */

/* button{
    background:hsl(0, 75%, 60%) !important;
}

.delete-circle{
    background:hsl(0, 75%, 60%) !important;
}

.social-icon{
    color: hsl(0, 75%, 60%) !important;
} */

.light-theme .section-heading{
    font-family: serif;
    font-size: 2rem;
    color: rgb(43, 42, 42);
}

/* section headers */
.light-theme .section-heading::before,.light-theme .section-heading::after {
    display: none;
}


/* FONTS
FONTS
FONTS
FONTS
FONTS
*/

.dark-theme{
    font-family: monospace !important;
    /* text-transform: lowercase; */
}

.dark-theme header a,.dark-theme header div{
    font-size: 1.5rem;
}


.dark-theme h1{
    font-family: monospace !important;
    letter-spacing: .2em;
    /* text-transform: lowercase; */
    font-weight: 100 !important;
}

.dark-theme .one-liner{
    /* text-transform: lowercase !important; */
    font-family: monospace;
    font-size: 1.2rem;
}

.dark-theme h2{
    font-family: monospace !important;
    font-size: 2rem;
}

.dark-theme p{
    font-family: monospace !important;
    letter-spacing: .1em;
    font-size: 1.4rem;
    text-align: justify;
}

.dark-theme input,.dark-theme textarea{
    font-family: Georgia, 'Times New Roman', Times, serif !important;
}

.dark-theme input,.dark-theme button{
    font-family: Georgia, 'Times New Roman', Times, serif !important;
}