.tabs-cont{
    display: flex;
}

.tab{
    padding: 1em;
    cursor: pointer;
}

.tab:hover{
    color:rgb(225, 225, 225)
}

.tab.selected{
    border-bottom: none !important;

    border-radius: 10px 10px 0 0;
}
.tab.selected:hover{
    color:unset
}

.empty-space{
    flex-grow: 1;

}