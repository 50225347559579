.edit-dates-cont .event-table{
    background: #e8e8e8;
    margin-top: 1.5rem;
    padding: 1.5rem;
    padding-bottom: 0.7rem;
    border-radius: 14px;
}

.edit-dates-cont .event-table input[name="location"]{
    padding: 13px;
    padding-left: 10px;
}

.edit-dates-cont .event-table input[name="city"]{
    padding: 14px;
    padding-left: 10px;
}

.add-date{
    margin-top:2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-date button{
margin-left:1rem;
background:rgb(41 157 41 / 61%);
color:white;
}

.add-date button:hover{
    background:rgb(41 157 41 / 90%)
}

@media only screen and (max-width: 600px) {
    .event-form-cont {
        position: relative;
        background: #e8e8e8;
        max-width: 90vw;
    }
    .edit-dates-cont .event-table{
        padding: 0;
    }
    .event-form-cont input,.event-form-cont textarea {
        font-size: 1rem;
        padding: 0.3em !important;
        margin-bottom: 1em;
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0 3px 3px #00000003;
        cursor: pointer;
    }
    .event-table>tbody td:nth-child(odd) input {
        width: 115px;
    }
    .event-table td.right {
        text-align: left;
    }

    .edit-dates-cont .event-table input[name="location"]{
        width: 190px;
        padding: 7px 5px !important;
    }
    
    .edit-dates-cont .event-table input[name="city"]{
        width: 190px;
        padding: 7px 5px !important;
    }
    .event-table input.full-span {
        width: 97% !important;
    }
}