/* COLORS
//
//
//
*/

.dark-theme{
    --font-color:hsl(0, 77%, 95%);
    --secondary-font-color:red;
    --header-bg-color:black;
    --main-bg-color:hsla(0, 0%, 6%, 0.748);
    --alternating-bg-color:hsla(0, 0%, 4%, 0.781);
    --backdrop:url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/C%C3%A9vennes_France_night_sky_with_stars_02.jpg/640px-C%C3%A9vennes_France_night_sky_with_stars_02.jpg");
    color:var(--font-color) !important;
}
.dark-theme header{
    background-color: var(--header-bg-color) !important;
}

.dark-theme .slider{
    background-color: hsl(0, 75%, 60%)!important;
}

.dark-theme section{
    background: var(--main-bg-color) !important;

}
.dark-theme section.alternating:nth-child(even){
    background: var(--alternating-bg-color) !important;
}

.dark-theme h1{
    color:var(--font-color) !important;
}

/* inputs */
.dark-theme input,.dark-theme textarea{
    color:rgb(211, 228, 225) !important;
    background-color:#544949 ;
}

/* events */
.dark-theme .event-form-cont,.dark-theme .event-table{
    background: black !important;
}


/* links */
.dark-theme a{
    color:var(--font-color) !important;
}


/* buttons */

.dark-theme button{
    background:hsl(0, 75%, 60%) !important;
}

.dark-theme .delete-circle{
    background:hsl(0, 75%, 60%) !important;
}

.dark-theme .social-icon{
    color: hsl(0, 75%, 60%) !important;
}




/* FONTS
FONTS
FONTS
FONTS
FONTS
*/

.dark-theme{
    font-family: monospace !important;
    /* text-transform: lowercase; */
}

.dark-theme header a,.dark-theme header div{
    font-size: 1.5rem;
}


.dark-theme h1{
    font-family: monospace !important;
    letter-spacing: .2em;
    /* text-transform: lowercase; */
    font-weight: 100 !important;
}

.dark-theme .one-liner{
    /* text-transform: lowercase !important; */
    font-family: monospace;
    font-size: 1.2rem;
}

.dark-theme h2{
    font-family: monospace !important;
    font-size: 2rem;
}

.dark-theme p{
    font-family: monospace !important;
    letter-spacing: .1em;
    font-size: 1.4rem;
    text-align: justify;
}

.dark-theme input,.dark-theme textarea{
    font-family: Georgia, 'Times New Roman', Times, serif !important;
}

.dark-theme input,.dark-theme button{
    font-family: Georgia, 'Times New Roman', Times, serif !important;
}

main.dark-theme .event-table{
    font-size: 1.3rem;
    padding-left: 30px;
    padding-right: 30px;
}

main.dark-theme .event-table .emphasize{
    font-size: 1.8rem;
    /* text-transform: uppercase; */
}

main.dark-theme section.section .section-heading::before{
    box-shadow: 0 0 5px 5px #eeff0010;
    background-color: #eeff0010;
}
main.dark-theme section.section .section-heading::after{
    box-shadow: 0 0 5px 5px #eeff0010;
    background-color: #eeff0010;
}
main.dark-theme section.section .section-heading{
    background:  #eeff0010;
    box-shadow: 0 0 5px 5px #eeff0010;
}