.authentication-modal-overlay{
    background:rgb(0 0 0 / 65%)  !important;
}

.modal.authentication-modal{
    margin:0px;
    display:flex;
    top:5%;
    max-height: 90vh;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px #fdfdfd3d;
    overflow-y: scroll;
    scrollbar-width: 0;
}

.modal.authentication-modal::-webkit-scrollbar {
    display: none;
  }

.authentication-modal button.close-modal-button{
    padding: 0;
    margin: 0px;
    top: 5px;
    position: absolute;
    right: 12px;
    font-size: 2rem;
    background: #978f9e;
    color: rgb(1, 1, 0);
}
.authentication-modal button.close-modal-button:hover{
    color:white
}

.tabs-cont{
    background: #978f9e;
    border-radius: 10px 10px 0px 0px;
}

.authentication-modal .tab.selected{
    background: rgb(243, 243, 243);
}

.authentication-modal .tab{
    font-weight: 600;
    font-size: 1.2rem;
}

.authentication-modal input{
    max-width: 300px;
    margin:30px;
    margin-bottom:0;
}

.authentication-modal button{
    margin:30px;
    padding:12px;
}

@media only screen and (max-width: 600px){
    .modal.authentication-modal {
        /* position: fixed; */
        max-width: 90vw;
        top: 100px;
        border-radius: 10px;
        box-shadow: 5px 5px 10px 5px #fdfdfd3d;
    }
}