.own-page-header,.other-page-header{
    position: sticky;
    top: 0;
    z-index: 10;
}

.own-page-header>header,.other-page-header>header{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--nav-bg);

}


header{
    position: relative;
}

.other-page-header header {
    display: flex;
    justify-content: space-between

}

.header-left.personal{
    display: flex;
}

.other-page-header button{
    padding: .5rem;
    margin-right:10px;
}

.editing-toggle{
    position: absolute;
    right:0;
    top:50%;
    transform: translate(-20%,-50%);
    z-index: 20;
    grid-template-columns: 150px 100px;
}

.save-btn-cont{
    position: absolute;
    right:0;
    top:50%;
    transform: translate(-20%,-50%);
    z-index: 20;
}

.save-btn-cont button{
    padding: .5em;
}

@media only screen and (max-width: 600px) {

    .own-page-header>header, .other-page-header>header {
        justify-content: space-between;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .header-left a{
        padding: 0 !important;
    }

    .other-page-header button {
        margin:0 !important;
        margin-bottom: 10px !important;
        background:rgba(128, 128, 128, 0.453);
    }

    .other-page-header .header-left{
        transform: unset;
    }
    .editing-toggle{
        justify-items:flex-end;
        grid-template-columns: 80px 80px;
        position: unset;
        transform: unset;
        margin-bottom: unset;
    }
    .editing-toggle>div{
        text-align: right;
    }
    .editing-toggle .switch{
        justify-self: center;
    }
}