.hero>section{
    background-color: none !important;
    height: 600px;
    position: relative;
    padding: 0;
}

.hero>section>img.hero-img{
    width: 100vw;
    height: 600px;
    overflow:hidden;
    top:0;
    position:absolute;
}

.hero>section>.overlay{
    top:0;
    position:absolute;
    width: 100vw;
    height: 100%;
    background-color: hsl(276deg 82% 21% / 66%)
}

.hero>section>.content{
    top:0;
    position:absolute;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    width: 90vw;
    align-items: flex-start;
    justify-content: center;
}

.hero>section>.content>h1{
    color:white;
    font-size: 3.3rem;
}

.hero>section>.content>h3{
    color:white;
    font-size: 1.4rem;
    text-align: left;
    max-width: 500px;
    font-weight: 500;
}

.hero>section>.content>button{
    margin-top: 30px;
    background: rgb(239, 231, 6);
    font-size: 1.5rem;
    color: rgb(122, 0, 128);
}

@media only screen and (max-width: 600px) {
    .hero>section{
        height: 450px;
    }
    .hero>section>img.hero-img{
        /* width: 1000px; */
        height: 100%;

    }
    .hero h1{
        margin-bottom: 0;
    }

    .hero>section>.content{
        height: unset !important;
        padding-left: 0 !important;
    }

    .hero button{
        margin:50px auto
    }

  }