header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    background-color: var(--header-bg-color);
}

.main-header{
    padding: none;
    background-color: transparent;
    position: fixed;
    width: 100%;
}

.main-header.scrolled {
    background: #772d87;
}

.header-left a{
    display: flex;
    padding-left: 2rem;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
}

.header-left h2{
    /* color:rgb(228, 216, 216) !important; */
    font-size: 2.5rem;
    margin:0;
    transform:translateY(5px);
    font-family:Geneva, Tahoma, sans-serif;
    letter-spacing: 0;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-line: overline;
}

.main-header h2,.main-header h3{
    color:rgb(249, 244, 244) !important;
}

.main-header h2{
    font-size: 2rem;
}

.header-logo{
    height: 50px;
    margin: 10px 10px;
}

.header-right{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.header-right a,.header-right button{
    margin-right:30px;
    margin-top: 10px;
    cursor: pointer;
    color: white;
    font-size: 1rem !important;
    padding: .7em 1em !important;
    font-weight: 550;
    letter-spacing: 0.2em;
    text-transform: uppercase;
}

a.btn-secondary{
    text-decoration: none;
    border-radius: 5px;
}

.env-cont{
    padding:15px;
    padding-left: 0;
    color:var(--btn-secondary);
}

@media only screen and (max-width: 850px) {
    header{
        grid-template-columns: 1fr 1fr;
    }
    .main-header .header-right{
        grid-row:1;
        grid-column:2
    }
    .main-header .header-center{
        grid-column-start: 1;
        grid-column-end: 3;

    }
}

@media only screen and (max-width: 600px) {
    header {
        grid-template-columns: 1fr;
        background:#f0eff1;
    }
    .main-header{
        position: sticky;
        background: #772d87;
    }
    header>div{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        padding-left: 0 !important;
        margin-bottom: 1rem;
    }

    .header-left{
        margin-bottom: 0;
        transform: translateX(-15px);
    }
    .header-left h2 {
        font-size: 1.8rem;
    }
    .header-left img{
        height: 35px;
    }
    .header-center{
        margin:0
    }

    .main-header .header-center h3{
        margin-top: 0;
    }

    .main-header .header-right{
        grid-row:2;
        grid-column:1
    }
    .header-right a, .header-right button{
        margin-right:10px;
        margin-left:10px;
    }
    .header-right button{
        width: 140px;
    }
  }

  