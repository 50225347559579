.users-list{
    margin:auto;
    display:flex;
    flex-wrap: wrap;
}

.user-details{
    margin:20px;
    background: hsl(0, 14%, 95%);
    padding: 1rem;
    margin-top:.5rem;
    border-radius:10px;
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
    justify-content: space-between;
}

.user-details-img-cont{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background: black;
}

.user-details img{
    width: 100px;

}