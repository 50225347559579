.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #1d171734;
    z-index: 300;
}

.modal{
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top:50px;
    box-shadow: 3px 3px 5px 1px #2e2e2ec4;
    transform: translateX(-50%);
    background: rgb(243, 243, 243);
}

.modal .close-modal-button{
    position: relative;
    top: 0px;
    right: -80px;
}

.modal-content{
    padding-top:0
}

.modal-content h1{
    margin-top:0
}

@media only screen and (max-width: 600px){
    
}