.dragless-reorder-div{
    font-size: 1.6rem;
    padding:.5rem;
    background-color: grey;
    margin:.3rem;
}

.dragless-reorder-div svg{
    margin:.3rem 2rem;
    cursor: pointer;
}

.dragless-reorder-div:first-child .up{
    display: none;
}

.dragless-reorder-div:last-child .down{
    display: none;
}



